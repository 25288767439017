import React from 'react';
import Typed from 'react-typed';

const Hero = () => {
  return (
    <div className='text-white'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <p className='text-[hsl(357,100%,59%)] font-bold p-2'>
        REVOLUTIONIZED RADIOLOGY IMAGING
        </p>
        <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:py-6'>
        Welcome to U4RAD
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>
         We provide..
          </p>
          <Typed
          className='md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2'
            strings={['24/7 reporting', 'eRaaS', 'SOAS']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-gray-500'>We are an AI enabled integrated Image Reporting Platform</p>
        <div className='max-w-[800px] max-h-[200px] bg-white rounded-lg mt-8 p-4 flex items-center'>
          {/* Logo on the left */}
          <img
            src='https://xraidigital.com/Content/images/logo.png' // Replace with the actual path to your logo
            alt='Logo'
            className='w-26 h-16 mr-4' // Adjust the size of the logo as needed
          />
          {/* Text on the right */}
          <p className='text-gray-800 text-lg font-medium'>
            For point of care and TB testing please do visit{' '}
            <a
              href='https://xraidigital.com'
              target='_blank'
              rel='noopener noreferrer'
              className='text-[hsl(357,100%,59%)] underline'
            >
              xraidigital.com
            </a>
          </p>
        </div>
        {/*<button className='bg-[hsl(357,100%,59%)] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black'>KNOW MORE</button>*/}
      </div>
    </div>
  );
};

export default Hero;

import React from 'react';

const PrivacyPolicy = () => {
  return (
    <>
      <div className='w-full py-[6rem] bg-white mx-auto'>
        {/* Header Section */}
        <div className='w-full py-20 bg-black text-white px-6 mb-20'>
          <div className='lg:col-span-2 my-4'>
            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-center'>
              Privacy Policy
            </h1>
          </div>
        </div>

        {/* Content Section */}
        <div className='max-w-[1240px] mx-auto px-4 py-12'>
          <h2 className='text-4xl font-bold text-center mb-8'>Terms of Use</h2>
          <p className='text-xl text-gray-600 text-center mb-12'>
            Last updated on 6th February 2025
          </p>

          {/* Terms Content */}
          <div className='text-gray-700'>
            {/* Section 1: Introduction */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>1. Introduction</h3>
              <p>
                The terms and conditions contained here in after ("Terms of Use") shall apply to your ("you", "your" or "User") use of the domain name u4rad.com and any other linked pages, images, features, and content offered from time to time by U4RAD Technologies LLP ("the Company" or "we" or "our" or "us") (collectively referred to as "Website") and the usage of the Services.
              </p>
              <p>
                By accessing the Website and/or using any of the Services, you acknowledge to have read, understood, and you consent to be governed and bound by these Terms of Use and the Privacy Policy ("Privacy Policy"). This constitutes a binding and enforceable agreement between you and the Company. If you do not understand the Terms of Use or the Privacy Policy, or do not accept or agree to any part of them, then you are requested to not use the Website or any of the Services provided by the Company.
              </p>
              <p>
                These Terms of Use are an electronic record in terms of the Information Technology Act 2000, as amended from time to time, ("IT Act") and rules framed there under. This electronic record is generated by a computer system and does not require any physical or digital signatures.
              </p>              
              <p>
                For the purpose of these Terms of Use, wherever the context so requires, the term "you" or "your" or "User" shall mean and include any natural or legal person who has agreed to these Terms of Use on behalf of himself/herself or any other legal entity.
              </p>
              <p>
                It is further clarified that the Privacy Policy (that is provided separately), forms an integral part of these Terms of Use and should be read in conjunction.
              </p>
            </div>

            {/* Section 2: Changes to the Terms of Use */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>2. Changes to the Terms of Use</h3>
              <p>
                The Company reserves the right, at its discretion, to change, modify, amend, add, or remove portions of this Terms of Use at any time with no prior notice. We recommend you review this Terms of Use periodically to ensure that you are aware of the current website usage practices of the Company. Any such changes will be effective as soon as the amended Terms of Use have been published on the Website. By continuing to use this website and the Services after changes are made, you agree to be bound by the revised/amended Terms of Use. You are solely responsible for understanding and complying with all applicable laws of your specific jurisdiction that may be applicable to you in connection with your use of the Website, Services.
              </p>
            </div>

            {/* Section 3: Privacy and Protection of Data or Information */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>3. Privacy and Protection of Data or Information</h3>
              <p>
                Your privacy is extremely important to us. Upon acceptance of these Terms of Use, you confirm that you have read, understood, and unequivocally accepted our Privacy Policy.
              </p>
            </div>

            {/* Add more sections as needed */}
            {/* Example: Section 4 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>4. Definitions</h3>
              <p>
                "Content" shall include, without limitation, information, data, text, logos, photographs, videos, audio clips, animations, written posts, articles, comments, software, scripts, graphics, themes, and interactive features generated, provided, or otherwise made accessible on the Website, including in relation to a Service.
              </p>
              <p>
                "Service" shall include the provision of an artificial intelligence-assisted radiology reporting platform on the Website for optimizing services in relation to MRI Scan, CT Scan, X-ray, mammography, and chest X-ray-based Covid-19 detection.
              </p>
            </div>
            {/* Example: Section 5 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>5. Order Request Form and Availability</h3>
              <p>
                For placing any orders with the Company for the Services, Users are required to fill out an order request form with their name, contact details and other details as requested by the Company.You agree that any information so provided in the order request form is accurate, correct, complete and up to date. If you provide any information that is untrue, inaccurate, incomplete, or not current or contrary to the Terms of Use, we reserve the right to not provide the Service.
              </p>
              <p>
                In addition to the above mentioned, you understand that provision of services is subject to availability. We reserve the right to limit the scope of our services if the situation so requires.
              </p>
            </div>
            {/* Example: Section 6 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>6. Terms of Use of the Services</h3>
              <p>
                By agreeing to the use of our services, you understand and agree that our services are based on clinical decision support tools that may use Artificial Intelligence, the results of which may depend on various factors. The probability of the result is based on historical data. The result provided are for clinical decision making by a clinician in correlation with other findings/ reports, and it is not a diagnosis to be used for any clinical purpose The Company shall not be responsible for any incorrect information or diagnosis contained in the reporting/ analysis under our Services. Although the Company takes reasonable efforts that the Services are regularly monitored and updated, we cannot be held liable for any inaccuracies or incompleteness of information in relation to our Services which results in a difference of opinion from any independent radiologist/ clinician.
              </p>
            </div>
            {/* Example: Section 7 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>7. Representation and warranties</h3>
              <p>
                By using the Services, you represent and warrant that you are legally entitled to enter this Website and you agree to these Terms of Use and you are of legal age as per applicable laws to enter into an enforceable agreement with us. 
              </p>
              <p>
                You agree to use the Services only for purposes that are permitted by (a) the Terms of Use and (b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.
              </p>
              <p>
                Unless you have been specifically permitted to do so in a separate agreement with the Company, you agree you will not reproduce, rebrand, remarket, duplicate, copy, sell, trade or resell (as the case may be) the Services for any purpose. If you reproduce, rebrand, remarket, duplicate, copy, sell, trade or resell the Servicesfor any purpose, you will solely liable for such act and the Company may take legal action against you.
              </p>
            </div>
            {/* Example: Section 8 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>8. Orderand installation of the Services</h3>
              <p>
                All Services are subject to availability. Any User who wishes to purchase our Services from the Website can do so by contacting us at contact@u4rad.com. Once the payment has been made, the Company is not liable to the User for any refund/ replacement, under any circumstance, for any subsequent complaints in respect of any services provided by the Company.
              </p>
              <p>
                Once the payment has been made, a user shall not be allowed to cancel the placed order for the Services. 
              </p>
              <p>
                After the payment has been made in respect of a Service, installation of the software in relation to such Service shall be done by a Company representative authorized in this regard, within 3 business days.
              </p>
            </div>
            {/* Example: Section 9 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>9. Online payment</h3>
              <p>
                All Users who place orders on the Website will be required to pay the full price of the Services availed for us to process the order. We avoid providing the option to pay by cash for our services, however if there are unavoidable circumstances, it will be permitted as per management�s discretion.All payments on the Website are securely processed through our payment gateway partner. We accept most major credit and debit cards,including Visa, MasterCard and American Express. The Company shall be under no liability whatsoever regarding any loss or damage arising directly or indirectly out of the decline of authorization of any transaction.
              </p>
            </div>
            {/* Example: Section 10 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>10. Intellectual Property Rights</h3>
              <p>
                We have an exclusive and perpetual ownership of all rights, title and interest of the Website and its Content. No User, agent or any third party shall claim their rights on the Website or the Content. You are granted a limited license only, subject to the restrictions provided in these Terms of Use, for the purpose of viewing/accessing the Contents contained on the Website.
              </p>
              <p>
                The Content of the Website isthe property of the Company. No part of this website can be reproduced, copied, stored or transmitted in any form or by any means without the prior written consent of the Company. Further, you acknowledge and agree that the Company owns all intellectual property rights, title and interest in and to the Content.No Content can be used without the Company�s explicit written consent. The Company may take appropriate legal steps against any person to prevent any such unauthorized use of its Content or any breach of the Company�s intellectual property rights.
              </p>
              <p>
                Unless you have been expressly authorized to do so in writing by the Company, you agree that in using the Services, you will not use any trademark, service mark, trade name, logo of the Company except in the manner provided in the Terms of Use.
              </p>
            </div>
            {/* Example: Section 11 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>11. Use limitations</h3>
              <p>
                As a condition of your use of the Website, you warrant to the Company that you will not use the Website for any purpose that is unlawful or prohibited by these Terms of Use. You are expressly and emphatically restricted from all the following:
                <br/><br/>
                (a) publishing any website material or content in any media;
                <br/>
                (b) selling, sublicensing and/or otherwise commercializing any Website material or Content;
                <br/>
                (c) publicly performing and/or showing any website material or content;
                <br/>
                (d) using this Website in any way that is, or may be, cause damage, disruption, overburden or otherwise impair the functioning of the Website;
                <br/>
                (e) using this Website in any way that impacts user access to this Website;
                <br/>
                (f) engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this website, or while using this website;
                <br/>                
                (g) using this website to engage in any advertising or marketing;
                <br/>
                (h) misrepresent your relationship with us and do any act that may infringe any of our intellectual property rights, or damage our reputation and goodwill;
                <br/>
                (i) reproduce, copy, reverse engineer or decompile the contents of the Website for any purpose or make any modification to data and information on the Website without due authorization;
                <br/>
                (j) "deep-linking", "page-scraping", use of "robots", "web-spiders", "web-crawlers" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or its materials and Content or in any way reproduce or circumvent the navigational structure or presentation of the Website, to obtain or attempt to obtain any materials, documents, Content or information through any means not purposely made available through the Website;
                <br/>
                (k) gain or attempt to gain unauthorized access to the Website or any part there of, or any other systems or networks connected to the Website or to any server, computer, network, or to any of the services offered on or through the Website, by hacking, password "mining" or any other illegitimate means.
               </p>
            </div>
            {/* Example: Section 12 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>12. Limitation of Liability</h3>
              <p>
                To the maximum extent permitted by applicable law, in no event shall the Company be liable to you for any special, incidental, indirect, punitive or consequential damages whatsoever (including, without limitation, damages for loss of use, loss of business profits, business interruption, loss of information, or any other pecuniary loss) arising out of the use of, or inability to use or access, the Website or Services for any security breach or any virus, bug, unauthorized intervention, defect, or technical malfunctioning of the Website or the Services, whether or not foreseeable or whether or not the Company has been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortious action, or any other claim arising out, of or in connection with, your use of, or access to, the application or the content. Further, the Company shall not be liable to you for any temporary disablement, permanent discontinuance or modification of the Website or the Services by the Company or for any consequences resulting from such actions.
              </p>
              <p>
                The Company and/or its respective affiliates makeno representations about the suitability, reliability, availability, timeliness and accuracy of the information and related content contained in the Website for any purpose. All such information and related content are provided "as is" without warranty of any kind. The Company hereby disclaims all warranties and conditions with regard to this information and related content, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title and non-infringement.
              </p>
              <p>
                In no event shall the Company and/or its affiliates be liable for any direct, indirect, punitive, incidental, special, consequential damages or any damages whatsoever including, without limitation, damages for or in relation to: (a) loss of use, data or profits arising out of or in any way connected with the use or performance of Website or the Services; (b) any delay or inability to use website or services; (c) the provision of or failure to provide any information or services; (d) or otherwise arising out of the use of the Website or the Services whether or not all the above losses, claims or damages are based on contract, tort, negligence, strict liability, even if the Company and/or its affiliates and/or its associates have been advised of the possibility of such losses, claims or damages.
              </p>
            </div>
            {/* Example: Section 13 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>13. Termination</h3>
              <p>
                We reserve the right to modify, change, alter or discontinue any aspect of the Website, including the description of the Services at any time. Unless otherwise provided in these Terms of Use, your right to use of the Website and the Services may be terminated/ suspended by the Company without assigning any reasons at any time at the sole discretion of the Company. Upon termination / suspension, you shall not have the right to use or access the Website and the Services.
              </p>
            </div>
            {/* Example: Section 14 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>14. Governing Law, Settlement of Disputes and Jurisdiction</h3>
              <p>
                These Terms of Useand any dispute or claim arising under it will be governed by and construed in accordance with the laws of India. The User agrees that any legal action or proceedings arising out of these Terms of Use or in connection with these Terms of Use may be brought exclusively in the competent courts/tribunals having jurisdiction in Gurugram, Haryana and irrevocably submit themselves to the jurisdiction of such courts/ tribunals.
              </p>
            </div>
            {/* Example: Section 15 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>15. Indemnity</h3>
              <p>
                You agree to indemnify us and other persons involved in creating this Website,or in the provision of Services, for all damages, losses and costs (including legal costs) which arise out of or relate to your use of this Website or our Services or any acts committed by you in violation of applicable laws and this Terms of Use. This indemnification includes, without limitation, liability relating to copyright infringement, defamation, invasion of privacy, trademark infringement and breach of these Terms of Use.
              </p>
            </div>
            {/* Example: Section 16 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>16. Force Majeure</h3>
              <p>
                The Company shall be under no liability whatsoever in the event of non-availability of the Services or any portion of the Website occasioned by a Force Majeure Event. A "Force Majeure Event" shall mean any event that is beyond our reasonable control and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, etc.
              </p>
            </div>
            {/* Example: Section 17 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>17. Severability</h3>
              <p>
                If any of the provisions of these Terms of Use are deemed invalid, void, or for any reason unenforceable, that part of these Terms of Use will be deemed severable and will not affect the validity and enforceability of any remaining provisions of these Terms of Use.
              </p>
            </div>
            {/* Example: Section 18 */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>18. Complaints and Grievance Redressal</h3>
              <p>
                Any complaints or concerns regarding content of this website, comment or breach of these Terms of Use or any intellectual property of any user shall be immediately informed to the designated Grievance Officer as mentioned below via through email.
                <br/><br/>
                Name:Partha Dey
                <br/>
                Designation:Grievance Officer
                <br/>
                Email ID:Parthadey@u4rad.com

              </p>
            </div>
             
            {/* Continue adding sections as per your content */}

            {/* Section 19: Contact Information */}
            <div className='mb-8'>
              <h3 className='text-2xl font-bold mb-4'>19. Contact Information</h3>
              <p>
                For any queries on the use of the Services or our Website, please get in touch by filling in the form on the "Contact Us" page or email us at{' '}
                <a href='mailto:contact@u4rad.com' className='text-[hsl(357,100%,59%)]'>
                  contact@u4rad.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;